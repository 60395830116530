let origin = window.ORIGIN;
//社区达人首页
const talentUrl = `${origin}/gateway/hc-neighbour/talent/getPersonalTalentPageInfo`;
//社区达人申请
const postTalentUrl = `${origin}/gateway/hc-neighbour/talent/addTalentAuditInfoRecord`;
//申请详情查询
const postTalentDetailUrl = `${origin}/gateway/hc-neighbour/talent/getTalentAuditInfoById`;
// 查询用户的社群申请情况
const getUserAssStatusUrl = `${origin}/gateway/hc-neighbour/talent/getUserAssStatus`;
export { talentUrl, postTalentUrl, postTalentDetailUrl, getUserAssStatusUrl };
